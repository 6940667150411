
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";


.dataLoadingInProgress > * {
  opacity: 0.5;
}

@media screen and (min-height: 25rem) {
  .container {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    background-color: white;
    border-top: 1px solid #D9D9D9;
  }
}

@media screen and (max-height: 25rem) {
  .container {
    border-top: 1px solid #D9D9D9;
    margin-top: 10px;
    width: 100vw;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid map-get($primary, primary20);
  padding: $gap-2 $gap-4;
}

.clear {
  display: block;
  margin-left: auto;
}

.next {
  margin: $gap-4;
  button {
    display: block;
    margin-top: 10px;
    margin-left: auto;
  }
}

.line {
  width: 100vw;
  padding: 15px 0 5px;
}

.confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.back {
  padding: 0;

  div {
    padding: 0;
  }

  button {
    min-height: 40px;
    max-height: 40px;
    margin-top: 10px;
    padding-left: 5px;
    border: none !important;
  }
}
