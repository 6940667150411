@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.header {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-xlarge;
  line-height: $line-height-120;
  margin-bottom: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 3rem 3rem 3rem;
}

.body_text {
  color: map-get($primary, primary100);
  text-align: center;
  font-family: $font-family-text-font;
  font-size: $font-size-medium;
  line-height: $line-height-default-150;
}

.illustration {
  width: 10.625rem;
  height: 10.625rem;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.loading {
  margin-top: 5.25rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.3rem;
}
