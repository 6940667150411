@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.container {
  color: map-get($primary-colors, primary);
  font-size: $font-size-base;
  font-weight: 600;
  max-width: 46.9rem;
  margin-top: 5px;
}

@media (max-width: 750px) {
  .container {
    font-size: 0.875rem;
  }
}

@media (max-width: 650px) {
  .container {
    font-size: 0.75rem;
  }
}

.message {
  color: map-get($primary-colors, primary);
  font-size: $font-size-base;
  font-weight: 600;
  margin-top: 0.32rem;
}
