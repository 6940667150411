@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.loading {
  height: 100vh;
  width: 100vh;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.message {
  margin: 0.94rem;
  background-color: map-get($primary, primary10);
  border-radius: $border-radius-default;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;

  div:first-child {
    background-color: transparent;
    gap: 0.625rem;
    font-size: 1rem !important;

    span {
      color: map-get($primary, primary100);
      font-weight: 600;
    }

    div:nth-child(2) {
      display: block;

      a {
        text-decoration: underline !important;
        text-decoration-color: map-get($base, black) !important;

        span {
          font-weight: 400;
          color: map-get($base, black);
        }

        svg {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }

  path {
    color: map-get($primary, primary100);
  }
}

.progress {
  width: 100%;
  accent-color: #002147;
  height: 0.75rem;
  margin: 0 0 0.20rem 0;
}


.productsContainer {
  width: 100%;
  padding: 15.04px;
}

.productTitle {
  color: #002147;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.017em;
  text-align: left;
}

.gradeCreationProduct {
  margin-bottom: 1rem;
}

.processedLineItems {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.017em;
  text-align: left;
  color: #002147;
}

.title {
  font-family: $font-family-text-font;
  font-weight: $font-weight-700;
  font-size: $font-size-xlarge;
  color: map-get($primary, primary100);
  margin-bottom: 2rem;
}

.errorIcon {
  width: $icon-size-regular;
  height: $icon-size-regular;
}

.error {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.errorMessage {
  color: map-get($validation, validation-red100);
  font-weight: 550;
  margin-left: 10px;
}

.errorView {
  margin-bottom: 1.5rem;
}
