@import "~@oup/shared-front-end/src/styles/themes/base/sizes";

.container {
  margin: $gap-4 0;
}

@media screen and (max-height: 25rem) {
  .container {
    margin-bottom: 0px;
  }
}
