@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/mixins/a11y-hide";

.a11yHide{
  @include a11y-hide();
}

.wrap {
  display: block;

  > * + * {
    margin-top: $gap-2;
  }
  max-width: 6.25rem;
  min-width: 6.25rem;
  width: 6.25rem;
}

.label {
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;

  .wrap:not(.disabled) &:hover {
    cursor: pointer;
  }
}

.label p {
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-600;
  line-height: $line-height-default-150;
  color: map-get($primary, primary100);
  margin-top: $gap-2;
  margin-bottom: $gap-2;
  margin-top: $gap-2;
}

.labelPara {
  font-weight: $font-weight-700;
}

.labelSubPara {
  font-weight: $font-weight-400;
}

.validationMessage {
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;
  font-weight: $font-weight-600;
  line-height: $line-height-120;
  width: 6.25rem;

  &.valid {
    color: map-get($validation, validation-green100);
  }

  &.invalid {
    color: map-get($validation, validation-red100);
    font-weight: $font-weight-700;
  }

  &.warning {
    color: map-get($primary, primary100);
  }
}
