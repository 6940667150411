@import "~@oup/shared-front-end/src/styles/themes/base/colors";

.container {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  .illustration {
    margin-top: 15px
  }

  button {
    margin-top: 0.8rem;

    svg {
      color: map-get($primary, primary100);
    }

    &:hover {
      svg {
        color: map-get($primary, primary90);
      }
    }
  }
}

.panel {
  margin-top: 5px;
  margin-bottom: 10px;
  color: map-get($primary, primary100);
  text-align: center;
}
