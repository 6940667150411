@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/fonts";

.container {
  width: 100%;
  padding-bottom: 1rem;
}

.dataLoadingInProgress {
  opacity: 0.5;
}

.panel {
  margin: $gap-4;
  color: map-get($primary, primary100);

  h1 {
    margin: 0;
    margin-top: $gap-4;
    font-family:  $font-family-dm-serif-display;
    font-weight: $font-weight-400;
    font-size: $font-size-xlarge;
    line-height: $line-height-120;
  }

  p {
    margin: 0;
    font-family: $font-family-text-font;
    font-weight: $font-weight-400;
    font-size: $font-size-base;
    line-height: $line-height-default-150;
  }
}

@media screen and (max-height: 25rem) {
  .panelConfirmation {
    margin: 0.94rem 1.25rem 0rem 1.25rem;
  }

  .panelSingleItemConfirmation {
    margin: 0.94rem 1.25rem 0.32rem 1.25rem;
  }
}

@media screen and (min-height: 25rem) {
  .panelConfirmation {
    margin: $gap-4;
    padding-bottom: 7.5rem;
  }

  .panelConfirmationWithDeepLinks {
    margin: 0.94rem 1rem 0rem 1rem;
    padding-bottom: 10.625rem;
  }

  .panelConfirmationWithNoLinks {
    margin: 0.94rem 1rem 0rem 1rem;
    padding-bottom: 9.06rem;
  }

  .panelSingleItemConfirmation {
    margin: $gap-4;
    padding-bottom: 4.88rem;
  }
}

@media screen and (min-height: 25rem) {
  .multipleItemsError {
    margin-bottom: 10.1rem;
  }
}

@media screen and (min-height: 25rem) {
  .singleItemError {
    margin-bottom: 5rem;
  }
}

.loading {
  margin-top: 6.25rem;
}

.message {
  margin: $gap-4;
  background-color: map-get($primary, primary10);
  border-radius: $border-radius-default;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;

  div:first-child {
    background-color: transparent;
    gap: 0.625rem;
    font-size: 1rem !important;

    span {
      color: map-get($primary, primary100);
      font-weight: 600;
    }

    div:nth-child(2) {
      display: block;

      a {
        text-decoration: underline !important;
        text-decoration-color: map-get($base, black) !important;

        span {
          font-weight: 400;
          color: map-get($base, black);
        }

        svg {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }

  path {
    color: map-get($primary, primary100);
  }
}
