@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

nav ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: $gap-4;

  > * {
    flex-grow: 1;
  }

  button {
    width: 100%;
    border-radius: 0 !important;
  }

  li:first-of-type button {
    border-radius: 4px 0 0 4px !important;
  }

  li:last-of-type button {
    border-radius: 0 4px 4px 0 !important;
  }
}

.paginationDiv {
  min-width: 2.5rem;
  min-height: 2.25rem;
  border: $border-width-1 solid map-get($primary, primary50);
  color: map-get($primary, primary50);
  line-height: 1.2;
  padding-block: calc($gap-2 - $border-width-1);
  padding-inline: calc($gap-3 - $border-width-1);
  text-align: center;
  user-select: none;
}
