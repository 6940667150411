@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";
@import "~@oup/shared-front-end/src/styles/themes/base/fonts";

.container {
  width: 100%;
}

.dataLoadingInProgress {
  opacity: 0.5;
}

@media screen and (min-height: 25rem) {
  .container {
    margin-bottom: 5.4rem;
    &_with_selected_items {
      margin-bottom: 8.125rem;
    }

    &_single_item {
      margin-bottom: 3.125rem;
    }
  }
}

@media screen and (min-height: 25rem) {
  .selectionContainer {
    width: 100%;
    &_with_selected_items {
      margin-bottom: 8.125rem;
    }

    &_single_item {
      margin-bottom: 3.125rem;
    }
  }
}

.panel {
  margin: $gap-4 $gap-4;
  color: map-get($primary, primary100);

  h1 {
    font-family:  $font-family-dm-serif-display;
    font-weight: $font-weight-400;
    font-size: $font-size-xlarge;
    line-height: $line-height-120;
    margin: 0;
  }

  p {
    margin: 0;
    font-family: $font-family-text-font;
    font-weight: $font-weight-400;
    font-size: $font-size-base;
    line-height: $line-height-default-150;
  }
}

.loading {
  margin-top: 100px;
}

.message {
  margin: 1rem;
  background-color: map-get($primary, primary10);
  border-radius: $border-radius-default;
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;

  div:first-child {
    background-color: transparent;
    gap: 10px;
    font-size: 1rem !important;

    span {
      color: map-get($primary, primary100);
      font-weight: 600;
    }

    div:nth-child(2) {
      display: block;

      a {
        text-decoration: underline !important;
        text-decoration-color: map-get($base, black) !important;

        span {
          font-weight: 400;
          color: map-get($base, black);
        }

        svg {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
  }

  path {
    color: map-get($primary, primary100);
  }
}

.error {
  margin-bottom: 1rem;
}

.containerWithSelectedProducts {
  padding-bottom: $gap-6;
}
